






























import Vue from "vue";
import Component from "vue-class-component";

import ComponentContainer from "@/components/util/ComponentContainer.vue";

import { UiState } from "@/UiState";
import { OCRTicketItem } from "@/types/OCRTypes";
import { TableHeader } from "@/types/VuetifyTypes";

@Component({
  components: {
    ComponentContainer,
  },
})
export default class TicketHistoryComponent extends Vue {
  items: OCRTicketItem[] = UiState.getInstance().ocrTicket.history;

  get headers(): TableHeader[] {
    const result: TableHeader[] = [];

    result.push(this.createHeaderColumn("eventName"));
    result.push(this.createHeaderColumn("captain"));
    result.push(this.createHeaderColumn("teamname"));
    result.push(this.createHeaderColumn("eventItemName"));
    result.push(this.createHeaderColumn("userModel.email"));
    result.push(this.createHeaderColumn("tshirt"));
    result.push(this.createHeaderColumn("userModel.sex"));
    result.push(this.createHeaderColumn("startTime"));
    result.push(this.createHeaderColumn("disclaimer"));
    result.push(this.createHeaderColumn("conditionOfParticipation"));
    result.push(this.createHeaderColumn("generalTermsAndConditions"));
    result.push(this.createHeaderColumn("startNo"));
    result.push(this.createHeaderColumn("ticketCode"));

    return result;
  }

  private createHeaderColumn(field: string): TableHeader {
    return {
      text: this.$t(
        "ocr.tickets.history.table." + field.replaceAll(".", "_")
      ) as string,
      align: "center",
      sortable: false,
      filterable: false,
      value: field,
    };
  }
}
