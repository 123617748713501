
















































































































































































































































































import {
  mdiEmailOutline,
  mdiFileDownloadOutline,
  mdiContentCopy,
} from "@mdi/js";

import Vue from "vue";
import Component from "vue-class-component";

import ComponentContainer from "@/components/util/ComponentContainer.vue";

import debounce from "lodash/debounce";
import { DEBOUNCE_WAIT } from "@/utils/Constants";

import { UiState } from "@/UiState";
import { OCRTicket, OCRTicketStatus } from "@/types/OCRTypes";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

import {
  createLocalDownload,
  coerceValidFileName,
} from "@/utils/DownloadUtils";

@Component({
  components: {
    ComponentContainer,
  },
})
export default class TicketDetailsComponent extends Vue {
  ticket: OCRTicket = UiState.getInstance().ocrTicket;

  showMailConfirmation = false;
  showRegistrationCopyBtn = false;
  showChangeLinkCopyBtn = false;

  get emailIcon(): string {
    return mdiEmailOutline;
  }

  get ticketDownloadIcon(): string {
    return mdiFileDownloadOutline;
  }

  get copyIcon(): string {
    return mdiContentCopy;
  }

  get canDownloadTicket(): boolean {
    if (this.ticket === undefined || this.ticket === null) return false;

    return OCRTicketStatus.COMPLETED === this.ticket.currentTicket.status;
  }

  get ticketEvent(): string {
    const eventId = this.ticket.currentTicket.eventId;
    for (const event of UiState.getInstance().ocrEvents) {
      if (event.id === eventId) {
        return event.name;
      }
    }

    return "";
  }

  get newsLetter(): string {
    const subscription = this.ticket.currentTicket.mailSubscription;

    if (subscription) {
      return this.$t("ocr.tickets.details.newsletter.yes") as string;
    }

    return this.$t("ocr.tickets.details.newsletter.no") as string;
  }

  get hasRegistrationLink(): boolean {
    if (this.ticket === undefined || this.ticket === null) return false;
    if (
      this.ticket.currentTicket === undefined ||
      this.ticket.currentTicket === null
    )
      return false;
    if (
      this.ticket.currentTicket.status === undefined ||
      this.ticket.currentTicket.status === null
    )
      return false;
    if (OCRTicketStatus.NEW !== this.ticket.currentTicket.status) return false;

    return true;
  }

  get hasChangeLink(): boolean {
    if (this.ticket === undefined || this.ticket === null) return false;
    if (
      this.ticket.currentTicket === undefined ||
      this.ticket.currentTicket === null
    )
      return false;
    if (
      this.ticket.currentTicket.changeLink === undefined ||
      this.ticket.currentTicket.changeLink === null ||
      this.ticket.currentTicket.changeLink.length === 0
    )
      return false;

    return true;
  }

  onCopyRegistrationLinkHandlerClicked(): void {
    if (!this.hasRegistrationLink) return;

    navigator.clipboard.writeText(this.ticket.currentTicket.registrationLink);
  }

  onCopyChangeLinkHandlerClicked(): void {
    if (!this.hasChangeLink) return;

    navigator.clipboard.writeText(this.ticket.currentTicket.changeLink);
  }

  private downloadTicketFromApi(): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();

      resolve(ocrService.downloadTicket(this.ticket.currentTicket.id));
    });
  }

  private downloadTicket(): void {
    NotificationService.getInstance().indicateLoading(
      this.downloadTicketFromApi().then((blob) => {
        createLocalDownload(
          blob,
          coerceValidFileName(
            "" +
              this.ticket.currentTicket.id +
              this.$t("ocr.tickets.actions.downloadTicketFileSuffix")
          )
        );
      }),
      this.$t("ocr.tickets.actions.downloadTicketMessage") as string
    );
  }

  private downloadTicketDebounced() {
    debounce(() => {
      this.downloadTicket();
    }, DEBOUNCE_WAIT)();
  }

  onTicketDownloadClickedHandler() {
    this.downloadTicketDebounced();
  }

  private sendEmailViaApi(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();

      resolve(ocrService.sendMailToTicket(this.ticket.currentTicket.id));
    });
  }

  private sendEmail() {
    const notificationService = NotificationService.getInstance();

    notificationService
      .indicateLoading(
        this.sendEmailViaApi(),
        this.$t("ocr.tickets.actions.sendingMailMessage") as string
      )
      .then(() => {
        notificationService.notifySuccess(
          this.$t("ocr.tickets.actions.sentMailMessage", {
            email: this.ticket.user.email,
          }) as string
        );
      });
  }

  onSendEmailClickedHandler() {
    this.showMailConfirmation = false;
    this.sendEmail();
  }
}
