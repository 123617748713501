







import Vue from "vue";
import Component from "vue-class-component";

import TicketDetailsComponent from "./TicketDetailsComponent.vue";
import TicketHistoryComponent from "./TicketHistoryComponent.vue";

import { UiState } from "@/UiState";

@Component({
  components: {
    TicketDetailsComponent,
    TicketHistoryComponent,
  },
})
export default class TicketViewComponent extends Vue {
  uiState: UiState = UiState.getInstance();
}
