




import Vue from "vue";
import Component from "vue-class-component";
import TicketViewComponent from "../components/ticket/TicketViewComponent.vue";

import { Dictionary } from "vue-router/types/router";

import { UiState } from "@/UiState";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

@Component({
  components: {
    TicketViewComponent,
  },
})
export default class TicketView extends Vue {
  showView = false;

  uiState: UiState = UiState.getInstance();

  private extractTicketIdFromUrlParameters(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const ticketId = this.extractNumberFromUrlParameter(
        "ticketId",
        this.$route.params
      );
      if (ticketId === null) {
        reject(
          new Error(
            this.$t("errors.requiredParameterMissing", {
              parameterName: "ticketId",
            }) as string
          )
        );
        return;
      }

      resolve(ticketId);
    });
  }

  private extractNumberFromUrlParameter(
    paramName: string,
    routeParams: Dictionary<string>
  ): number | null {
    if (routeParams === null) return null;

    const valueAsString = routeParams[paramName];
    if (valueAsString === undefined || valueAsString === null) return null;

    const result = Number(valueAsString);
    if (isNaN(result)) return null;

    return result;
  }

  loadDataFromApi(ticketId: number): Promise<void> {
    const ocrService = OCRServiceProvider.getInstance().getService();

    return ocrService
      .getTicket(ticketId)
      .then((ocrTicket) => {
        this.uiState.ocrTicket = ocrTicket;
      })
      .then(() => ocrService.getEvents())
      .then((events) => {
        this.uiState.ocrEvents = events;
      });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.extractTicketIdFromUrlParameters().then((ticketId) =>
          this.loadDataFromApi(ticketId)
        ),
        this.$t("messages.loadingData") as string
      )

      .then(() => {
        this.showView = true;
      })
      .catch((err) => {
        this.showView = false;
        notificationService.notifyError(err.message);
      });
  }

  mounted(): void {
    this.loadData();
  }
}
